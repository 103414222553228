$(document).ready(function() {

    // Custom select boxes
    $('.select select').selectBoxIt({
        dynamicPositioning : false,
        downArrowIcon      : 'icon-expand-more',
        aggressiveChange   : true,
        autoWidth: false
    });

    // Custom multiple select box
    $('.multiselect select').select2({
        closeOnSelect: false,
        dropdownAutoWidth: true,
        width: '100%',
        templateResult: function(data) {
            return $('<span>').text(data.text);
        }
    });

    // Select2 ClearSearchOnSelect Fix
    $('.multiselect select').on('select2:select', function() {
        $(this).parent().find('.select2-search__field').val('');
    });

    // Select2 Set Default Selections
    $('.multiselect select').each(function() {
        if($(this).data('selected').length) {
            $(this).val($(this).data('selected')).trigger('change');
        }
    });

    // Select2 JumpToTop Fix - https://github.com/select2/select2/issues/1513
    var scrollTop;
    $('.select2').prev('select').on('select2:selecting', function (event) {
        var $pr = $('#' + event.params.args.data._resultId).parent();
        scrollTop = $pr.prop('scrollTop');
    }).on('select2:select', function (event) {
        var $pr = $('#' + event.params.data._resultId).parent();
        $pr.prop('scrollTop', scrollTop);
    });

    // Masked inputs
    if ($('input[type="tel"]').length > 0) {
        $('input[type="tel"]').mask('(999) 999-9999');
    }

    if ($('input[class*="open-popup-link"]').length > 0) {
        $('.open-popup-link').magnificPopup({
            type: 'inline',
            midClick: true
        });
    }

    if ($('input[class*="ajax-popup-link"]').length > 0) {
        $('.ajax-popup-link').magnificPopup({
            type: 'ajax'
        });
    }

    if ($('[data-toggle="modal image"]').length > 0) {
        $('[data-toggle="modal image"]').magnificPopup({
            type: 'image',
            removalDelay: 500,
            callbacks: {
                beforeOpen: function() {
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            closeOnContentClick: true,
            midClick: true
        });
    }

    // Initialize DataTables
    $('.enableDT').DataTable({
        responsive: true
    });


    // Scroll to top of document
    $('#back-to-top').click(function() {
        var src = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(src).offset().top,
            easing: 'easeInOutQuart'
        }, 1000);

        return false;
    });

    // Match heights of cards by row. Includes resize and throttle in master .
    var mh_options = {
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    };

    $('.equal-blocks div').matchHeight(mh_options);
    $('.band .link-lists').matchHeight(mh_options);

    // Global search suggestions 
    $searchBar = $('.search [type="search"]');
    $searchBar.on('focus', function() {
        $(this).closest('.search').addClass('show-results');
    });
    $searchBar.on('blur', function() {
        $(this).closest('.search').removeClass('show-results');
    });
    $searchBar.on('keyup', function() {
        if ($searchBar.val().length > 0) {
            $searchBar.closest('.search').find('.popular h3').text('Suggestions...');
            $searchBar.closest('.search').find('.trending').hide();
        } else {
            $searchBar.closest('.search').find('.popular h3').text('Popular Searches on ncdcr.gov');
            $searchBar.closest('.search').find('.trending').show();
        }
    });

    var availableTags = [
        "ActionScript",
        "AppleScript",
        "Asp",
        "BASIC",
        "C",
        "C++",
        "Clojure",
        "COBOL",
        "ColdFusion",
        "Erlang",
        "Fortran",
        "Groovy",
        "Haskell",
        "Java",
        "JavaScript",
        "Lisp",
        "Perl",
        "PHP",
        "Python",
        "Ruby",
        "Scala",
        "Scheme"
    ];
    
    $('.search [type="search"]').autocomplete({
        source: availableTags,
        appendTo: '.popular',
        open: function() {
            $('.ui-autocomplete').css({
                'top': 0
            });
        }
    });
    $('.search-trigger').on('click', function(e) {
        $('.header-search').toggleClass('is-toggled');

        if ($('.header-search').hasClass('is-toggled')) {
            $('.header-search input[type="search"]').focus();
        }
    });


    // Desktop navigation
    $mainMenu = $('.mainMenu');
    if (navigator.appVersion.indexOf("MSIE 8.") != -1) {
        $mainMenu.removeClass('flyout-panel');

        if (!Modernizr.svg) {
            var imgs = document.getElementsByTagName('img');
            var svgExtension = /.*\.svg$/
            var l = imgs.length;
            for (var i = 0; i < l; i++) {
                if (imgs[i].src.match(svgExtension)) {
                    imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
                }
            }
        }
    }
    // enquire.register('screen and (min-width: 1024px)', {
    //     match: function() {
    //         $mainMenu.removeClass('flyout-panel');
    //     },
    //     unmatch: function() {
    //         $mainMenu.addClass('flyout-panel');
    //         // remove desktop styles applied for mega menus
    //         $('*', $mainMenu).attr({
    //             'style': ''
    //         });
    //     }
    // });


    // Pin desktop menu
    if ( $('nav.topical-nav').length ) {

        var controller;
        var scene;

        // enquire.register('screen and (min-width: 1024px)', {
        //     match: function() {
        //         controller = new ScrollMagic.Controller({
        //             loglevel: 0
        //         });
        //         scene = new ScrollMagic.Scene({
        //             offset: 80
        //         })
        //         .setPin("nav.topical-nav", {
        //             pushFollowers: false
        //         })
        //         .addTo(controller);
        //     },
        //     unmatch: function() {
        //         controller = controller.destroy(true);
        //         scene = scene.destroy();
        //     }
        // });

    }


    // Toggle user feedback
    $('#user-feedback .toggle-container').addClass('visuallyhidden');
    $('#user-feedback .toggle-trigger').click(function() {
        $('#user-feedback .toggle-container').toggleClass('visuallyhidden');
        $('#user-feedback .toggle-container').toggleClass('is-active');
    });
    $('#user-feedback .toggle-escape').click(function() {
        $('#user-feedback .toggle-container').addClass('visuallyhidden');
        $('#user-feedback .toggle-container').removeClass('is-active');
    });
    

    // Activate jQuery UI datepicker
    var $datePickerEl = $('.datepicker');
    if ($datePickerEl.length > 0) {
        $datePickerEl.datepicker();
    }

    var $datePickerEl = $('.datepicker-month-year');
    if ($datePickerEl.length > 0) {
        $datePickerEl.datepicker({
            changeMonth: true,
            changeYear: true
        });
    }

    // Toggle Search Options
    $('#filter-options-toggle').click(function() {
        $(this).toggleClass('ghost');
        $('#filter-options').toggleClass('is-hidden');
    });
    // enquire.register('screen and (min-width: 1024px)', {
    //     match: function() {
    //         $('#filter-options-toggle').hide();
    //         $('#filter-options').removeClass('is-hidden');
    //     },
    //     unmatch: function() {
    //         $('#filter-options-toggle').show();
    //         $('#filter-options').addClass('is-hidden');
    //     }
    // });


    // Initialize Topical Menu Overflow
    function resizeOverflow() {
        debounce(overflowmenu_extension(), 50);
        debounce($('.topical-nav').overflowmenu('refresh'), 50);
    }

    // enquire.register('screen and (min-width: 1024px)', {
    //     match: function() {
    //         // Setup Overflow Menu
    //         $('.topical-nav').overflowmenu({
    //             label: ''
    //         });

    //         // trigger b/c safari seems slow to pickup extra items
    //         setTimeout(function(){
    //             $('.topical-nav').overflowmenu('refresh')
    //             overflowmenu_extension()
    //         }, 200);

    //         // Setup Overflow Menu Extension
    //         overflowmenu_extension();
    //         $(window).resize(resizeOverflow);
    //     },
    //     unmatch: function() {
    //         $('.topical-nav').overflowmenu('destroy');
    //         $(window).off("resize", resizeOverflow);
    //     }
    // });


    // Initialize Document Revision History Toggle
    var revToggle = $('#doc-history-toggle'),
        revInfo = $('#doc-history-info');

    if (revToggle.length && revInfo.length) {
        var revCount = revInfo.find('li').length;

        // Add counter
        revToggle.find('a').after(' (' + revCount + ')');

        // Hide history
        revInfo.addClass('visuallyhidden');

        // Toggle history
        revToggle.find('a').click(function() {
            revInfo.toggleClass('visuallyhidden');
            return false;
        });
    }

    // Fields w/ none of the above Options
    $(".has-option-none .clear-fields").on("change", function() {
        if ( $(this).is(":checked") ) {
          $(this).parents(".has-option-none").find('input[type="checkbox"]:not(.clear-fields)').prop('checked', false);
          $(this).parents(".has-option-none").find('input[type="text"]').val("");
        }
    });

    $(".has-option-none  input[type='checkbox']:not(.clear-fields)").on("change", function() {
        $(this).parents(".has-option-none").find('.clear-fields').prop('checked', false);
     });

});